import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Registrando o service worker para ativar o PWA
serviceWorkerRegistration.register(); // Agora o PWA está ativado

// Se quiser monitorar o desempenho do app, pode usar o reportWebVitals
reportWebVitals();
