import React, { useState, useRef, useEffect, useCallback } from 'react';
import { jsPDF } from 'jspdf';
import logo from './assets/logo.jpg'; // Certifique-se de que o caminho está correto
import './App.css'; // Mantenha apenas a importação do CSS aqui
import { openDB } from 'idb';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './icons'; // Certifique-se de que este arquivo está configurado corretamente
import InputMask from 'react-input-mask';

// Configuração do banco de dados
const dbPromise = openDB('photo-report-db', 1, {
  upgrade(db) {
    if (!db.objectStoreNames.contains('photos')) {
      db.createObjectStore('photos', {
        keyPath: 'id',
        autoIncrement: true,
      });
      console.log('Object store "photos" criada.');
    }
  },
});

// Função para redimensionar uma imagem e ajustar para modo paisagem
const resizeAndRotateToLandscape = (base64Str, maxWidth = 1024, maxHeight = 1024) => {
  return new Promise((resolve) => {
    let img = new Image();
    img.src = base64Str;
    img.onload = () => {
      let canvas = document.createElement('canvas');
      let width = img.width;
      let height = img.height;

      if (width !== height && width < height) {
        // Rotaciona para paisagem se for retrato
        canvas.width = height;
        canvas.height = width;
        let ctx = canvas.getContext('2d');
        ctx.translate(canvas.width / 2, canvas.height / 2);
        ctx.rotate((90 * Math.PI) / 180);
        ctx.drawImage(img, -img.width / 2, -img.height / 2);
      } else {
        // Caso já esteja em paisagem ou quadrado
        canvas.width = width;
        canvas.height = height;
        let ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);
      }
      resolve(canvas.toDataURL('image/jpeg', 0.7));
    };
    img.onerror = (error) => {
      console.error('Erro ao redimensionar a imagem:', error);
      resolve(base64Str);
    };
  });
};

// Função para rotacionar uma imagem
const rotateImage = (imageDataUrl, rotation) => {
  return new Promise((resolve) => {
    let img = new Image();
    img.src = imageDataUrl;
    img.onload = () => {
      let canvas = document.createElement('canvas');
      let ctx = canvas.getContext('2d');

      if (rotation === 90 || rotation === 270) {
        canvas.width = img.height;
        canvas.height = img.width;
      } else {
        canvas.width = img.width;
        canvas.height = img.height;
      }

      ctx.translate(canvas.width / 2, canvas.height / 2);
      ctx.rotate((rotation * Math.PI) / 180);
      ctx.drawImage(img, -img.width / 2, -img.height / 2);

      resolve(canvas.toDataURL('image/jpeg', 1.0));
      console.log(`Imagem rotacionada em ${rotation}°.`);
    };
    img.onerror = (error) => {
      console.error('Erro ao rotacionar a imagem:', error);
      resolve(imageDataUrl);
    };
  });
};

// Função para enumerar câmeras e pegar a traseira manualmente
const getRearCameraId = async () => {
  const devices = await navigator.mediaDevices.enumerateDevices();
  const videoDevices = devices.filter(device => device.kind === 'videoinput');
  const rearCamera = videoDevices.find(device => device.label.toLowerCase().includes('back'));
  console.log('Câmeras disponíveis:', videoDevices);
  return rearCamera ? rearCamera.deviceId : null;
};

// Função para adicionar uma nova foto
const addPhoto = async (photo, description = '', rotation = 0) => {
  try {
    const db = await dbPromise;
    const allPhotos = await db.getAll('photos');
    const positions = allPhotos.map(p => p.position);
    const maxPosition = positions.length > 0 ? Math.max(...positions) : 0;
    const position = maxPosition + 1;
    const id = await db.add('photos', { photo, description, position, rotation });
    console.log(`Foto adicionada com ID: ${id}`);
  } catch (error) {
    console.error('Erro ao adicionar foto:', error);
  }
};

// Função para atualizar uma foto
const updatePhoto = async (id, updatedData) => {
  try {
    const db = await dbPromise;
    const existingPhoto = await db.get('photos', id);
    if (existingPhoto) {
      const updatedPhoto = { ...existingPhoto, ...updatedData };
      await db.put('photos', updatedPhoto);
      console.log(`Foto com ID ${id} atualizada.`);
    } else {
      console.warn(`Foto com ID ${id} não encontrada.`);
    }
  } catch (error) {
    console.error('Erro ao atualizar foto:', error);
  }
};

// Função para remover uma foto do IndexedDB
const removePhotoFromDB = async (id) => {
  try {
    const db = await dbPromise;
    await db.delete('photos', id);
    console.log(`Foto com ID ${id} removida do IndexedDB.`);
  } catch (error) {
    console.error('Erro ao remover foto do IndexedDB:', error);
  }
};

// Função para carregar todas as fotos
const loadPhotos = async () => {
  try {
    const db = await dbPromise;
    const allPhotos = await db.getAll('photos');
    console.log('Fotos carregadas do IndexedDB:', allPhotos);
    allPhotos.sort((a, b) => a.position - b.position);
    return allPhotos;
  } catch (error) {
    console.error('Erro ao carregar fotos:', error);
    return [];
  }
};

// Componente de item de foto
const PhotoItem = ({
  photo,
  id,
  index,
  totalPhotos,
  setDescription,
  removePhoto,
  setPhotoPosition,
  rotatePhoto, // Certifique-se de que a função é passada corretamente
}) => {
  const downloadPhoto = () => {
    const link = document.createElement('a');
    link.href = photo.photo;
    link.download = `foto_${photo.position}.jpg`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    console.log(`Foto ${photo.position} baixada.`);
  };

  const positionOptions = Array.from({ length: totalPhotos }, (_, i) => i + 1);

  const handleRemove = () => {
    const confirmRemoval = window.confirm('Você tem certeza que deseja apagar esta foto?');
    if (confirmRemoval) {
      removePhoto(id);
    }
  };

  return (
    <div className="photo-item-container">
      <div className="photo-image-container">
        <img
          src={photo.photo}
          alt={`Foto capturada ${photo.position}`}
          className="photo-item"
        />
      </div>
      <div className="rotation-indicator">
        <p>Rotação: {photo.rotation}°</p>
      </div>
      <div className="photo-item-controls">
        <div className="photo-position-selector">
          <label htmlFor={`position-select-${id}`}>Posição</label>
          <select
            id={`position-select-${id}`}
            value={photo.position}
            onChange={(e) => setPhotoPosition(id, parseInt(e.target.value))}
          >
            {positionOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <textarea
          placeholder="Adicione uma descrição breve"
          value={photo.description}
          onChange={(e) => setDescription(id, e.target.value)}
        />
        <div className="photo-item-buttons">
          <button onClick={handleRemove}>Apagar</button>
          <button onClick={downloadPhoto}>Download</button>
          <button onClick={() => rotatePhoto(id)}>Rotacionar</button>
        </div>
      </div>
    </div>
  );
};

// Função para alternar o modo da lanterna
const toggleTorchMode = (currentMode) => {
  return currentMode === 'on' ? 'off' : 'on';
};

function PhotoReportApp() {
  // Estados
  const [photos, setPhotos] = useState([]);
  const [isRearCamera, setIsRearCamera] = useState(true);
  const [selectedGroup, setSelectedGroup] = useState(() => {
    return localStorage.getItem('selectedGroup') || '1';
  });
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);
  const [boNumber, setBoNumber] = useState(() => {
    return localStorage.getItem('boNumber') || '';
  });
  const [version, setVersion] = useState(() => {
    return localStorage.getItem('version') || '1';
  });
  const [flash, setFlash] = useState(false);
  const [torchMode, setTorchModeState] = useState('off');
  const [torchSupported, setTorchSupported] = useState(false);
  const [torchError, setTorchError] = useState(false);
  const [isLandscape, setIsLandscape] = useState(
    window.innerWidth > window.innerHeight
  );

  // Refs
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const streamRef = useRef(null);
  const cameraContainerRef = useRef(null);

  // Funções de manipulação

  // Função para atualizar o torch (flash)
  const updateTorch = useCallback(() => {
    if (streamRef.current) {
      const videoTrack = streamRef.current.getVideoTracks()[0];
      const capabilities = videoTrack.getCapabilities();
      if (capabilities && capabilities.torch) {
        setTorchSupported(true);
        let torchOn = torchMode === 'on';

        videoTrack.applyConstraints({
          advanced: [{ torch: torchOn }],
        })
          .then(() => {
            console.log(`Torch ${torchOn ? 'ligada' : 'desligada'}.`);
          })
          .catch((e) => {
            console.warn('Não foi possível aplicar a lanterna:', e);
            if (torchMode === 'on') {
              setTorchError(true);
            }
            alert('Não foi possível ativar a lanterna. Verifique as permissões e a compatibilidade do dispositivo.');
          });
      } else {
        console.warn('Lanterna não é suportada neste dispositivo.');
        if (torchMode === 'on') {
          setTorchError(true);
        }
        setTorchSupported(false);
      }
    }
  }, [torchMode]);

  // Função para iniciar a câmera
  useEffect(() => {
    const startCamera = async () => {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
        console.log('Stream anterior interrompido.');
      }
      try {
        let constraints;
        if (isRearCamera) {
          const rearCameraId = await getRearCameraId();
          if (rearCameraId) {
            constraints = {
              video: {
                deviceId: { exact: rearCameraId },
                width: { ideal: 1280 },
                height: { ideal: 720 },
              },
            };
            console.log('Usando câmera traseira com deviceId:', rearCameraId);
          } else {
            constraints = {
              video: {
                facingMode: 'environment',
                width: { ideal: 1280 },
                height: { ideal: 720 },
              },
            };
            console.log('Usando câmera traseira com facingMode: environment');
          }
        } else {
          constraints = {
            video: {
              facingMode: 'user',
              width: { ideal: 1280 },
              height: { ideal: 720 },
            },
          };
          console.log('Usando câmera frontal com facingMode: user');
        }

        const mediaStream = await navigator.mediaDevices.getUserMedia(constraints);
        streamRef.current = mediaStream;
        if (videoRef.current) {
          videoRef.current.srcObject = mediaStream;
          console.log('Câmera iniciada e stream atribuída ao vídeo.');
        }

        // Verificar suporte ao torch após iniciar a câmera
        updateTorch();

      } catch (err) {
        console.error('Erro ao acessar a câmera:', err);
        alert('Erro ao acessar a câmera: ' + err.message);
      }
    };

    if (isLandscape && !isDesktop) {
      startCamera();
    }

    return () => {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
        console.log('Câmera desligada.');
      }
    };
  }, [isRearCamera, isLandscape, isDesktop, updateTorch]);

  // Atualiza o torch sempre que o modo de flash mudar
  useEffect(() => {
    updateTorch();
  }, [torchMode, updateTorch]);

  // Atualizar o estado de orientação ao redimensionar a janela
  useEffect(() => {
    const handleResize = () => {
      const landscape = window.innerWidth > window.innerHeight;
      setIsLandscape(landscape);
      setIsDesktop(window.innerWidth > 1024);
      console.log('Orientação alterada para:', landscape ? 'Paisagem' : 'Retrato');
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Carregar todas as fotos do IndexedDB no carregamento inicial
  useEffect(() => {
    const fetchPhotos = async () => {
      const loadedPhotos = await loadPhotos();
      setPhotos(loadedPhotos);
    };

    fetchPhotos();
  }, []);

  // Salvar o selectedGroup no localStorage
  useEffect(() => {
    localStorage.setItem('selectedGroup', selectedGroup);
  }, [selectedGroup]);

  // Salvar o boNumber no localStorage
  useEffect(() => {
    localStorage.setItem('boNumber', boNumber);
  }, [boNumber]);

  // Salvar a versão no localStorage
  useEffect(() => {
    localStorage.setItem('version', version);
  }, [version]);

  // Função para capturar a foto e ajustá-la para o modo paisagem
  const handleTakePhoto = async () => {
    console.log('handleTakePhoto chamado');
    const canvas = canvasRef.current;
    const video = videoRef.current;
    const context = canvas.getContext('2d');

    if (!video || !video.videoWidth || !video.videoHeight) {
      console.error('O vídeo não está pronto para capturar a foto.');
      alert('A câmera não está pronta. Por favor, aguarde um momento e tente novamente.');
      return;
    }

    const videoWidth = video.videoWidth;
    const videoHeight = video.videoHeight;

    canvas.width = videoWidth;
    canvas.height = videoHeight;

    context.drawImage(video, 0, 0, videoWidth, videoHeight);

    const photoDataUrl = canvas.toDataURL('image/jpeg', 1.0);

    const resizedPhoto = await resizeAndRotateToLandscape(photoDataUrl, 1024, 1024);
    console.log('Foto capturada e redimensionada.');

    await addPhoto(resizedPhoto, '', 0);

    const updatedPhotos = await loadPhotos();
    setPhotos(updatedPhotos);

    setFlash(true);
    console.log('Flash ativado.');

    setTimeout(() => {
      setFlash(false);
      console.log('Flash desativado.');
    }, 100);
  };

  // Função para importar fotos
  const handleImportPhotos = async (event) => {
    const files = Array.from(event.target.files);

    for (const file of files) {
      try {
        const reader = new FileReader();
        const photoDataUrl = await new Promise((resolve, reject) => {
          reader.onload = (e) => resolve(e.target.result);
          reader.onerror = (error) => reject(error);
          reader.readAsDataURL(file);
        });

        const resizedPhoto = await resizeAndRotateToLandscape(photoDataUrl, 1024, 1024);
        await addPhoto(resizedPhoto, '', 0);
        console.log('Foto importada e ajustada:', resizedPhoto);
      } catch (error) {
        console.error('Erro ao importar foto:', error);
      }
    }

    const updatedPhotos = await loadPhotos();
    setPhotos(updatedPhotos);
  };

  // Função para atualizar a descrição de uma foto
  const setDescriptionHandler = async (id, description) => {
    await updatePhoto(id, { description });
    const updatedPhotos = await loadPhotos();
    setPhotos(updatedPhotos);
    console.log(`Descrição da foto com ID ${id} atualizada:`, description);
  };

  // Função para atualizar a posição de uma foto
  const setPhotoPositionHandler = async (id, newPosition) => {
    try {
      const db = await dbPromise;
      const photo = await db.get('photos', id);
      if (!photo) {
        console.warn(`Foto com ID ${id} não encontrada.`);
        return;
      }

      if (photo.position === newPosition) return;

      const allPhotos = await db.getAll('photos');

      if (newPosition < photo.position) {
        const photosToUpdate = allPhotos.filter(p => p.position >= newPosition && p.position < photo.position && p.id !== id);
        for (let p of photosToUpdate) {
          await updatePhoto(p.id, { position: p.position + 1 });
        }
      } else {
        const photosToUpdate = allPhotos.filter(p => p.position <= newPosition && p.position > photo.position && p.id !== id);
        for (let p of photosToUpdate) {
          await updatePhoto(p.id, { position: p.position - 1 });
        }
      }

      await updatePhoto(id, { position: newPosition });

      const updatedPhotos = await loadPhotos();
      setPhotos(updatedPhotos);
      console.log(`Posição da foto com ID ${id} atualizada para ${newPosition}.`);
    } catch (error) {
      console.error('Erro ao atualizar posição da foto:', error);
    }
  };

  // Função para rotacionar uma foto
  const rotatePhotoHandler = async (id) => {
    try {
      const photo = photos.find((p) => p.id === id);
      if (!photo) {
        console.warn(`Foto com ID ${id} não encontrada.`);
        return;
      }

      const rotationIncrement = 90;
      const newRotation = (photo.rotation + rotationIncrement) % 360;
      const rotatedImage = await rotateImage(photo.photo, rotationIncrement);

      await updatePhoto(id, { photo: rotatedImage, rotation: newRotation });
      console.log(`Rotacionando foto com ID ${id} para ${newRotation}°`);

      const updatedPhotos = await loadPhotos();
      setPhotos(updatedPhotos);
    } catch (error) {
      console.error('Erro ao rotacionar a foto:', error);
    }
  };

  // Função para remover uma foto
  const removePhotoHandler = async (id) => {
    try {
      await removePhotoFromDB(id);

      const allPhotos = await loadPhotos();
      allPhotos.sort((a, b) => a.position - b.position);

      for (let i = 0; i < allPhotos.length; i++) {
        const photo = allPhotos[i];
        if (photo.position !== i + 1) {
          await updatePhoto(photo.id, { position: i + 1 });
        }
      }

      const updatedPhotos = await loadPhotos();
      setPhotos(updatedPhotos);
      console.log(`Foto com ID ${id} removida e posições atualizadas.`);
    } catch (error) {
      console.error('Erro ao remover a foto:', error);
    }
  };

  // Função para atualizar o BO number
  const handleBoInput = (e) => {
    let value = e.target.value.toUpperCase();

    // Remover caracteres inválidos
    value = value.replace(/[^A-Z0-9/]/g, '');

    setBoNumber(value);
  };

  // Função para alternar entre as câmeras
  const toggleCamera = () => {
    setIsRearCamera((prevState) => {
      console.log('Alternando câmera. Nova câmera traseira:', !prevState);
      return !prevState;
    });
  };

  const generatePDFReport = async () => {
  try {
    const doc = new jsPDF({
      orientation: 'portrait',
      unit: 'pt',
      format: 'a4',
    });
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    // Configurações de Margens e Layout
    const marginLeft = 20;
    const marginRight = 20;
    const marginTop = 20;
    const marginBottom = 25;
    const headerHeight = 100;
    const footerHeight = 20;
    const spacingBetweenElements = 5;
    const spacingBetweenImageAndDescription = 12;
    const photosPerPage = 2; // Duas fotos por página
    const lineHeight = 14; // Altura de linha para texto

    // Cálculo da Área de Conteúdo Disponível
    const contentHeight = pageHeight - marginTop - marginBottom - headerHeight - footerHeight - (photosPerPage - 1) * spacingBetweenElements;
    const maxBlockHeight = contentHeight / photosPerPage;
    const maxImageWidth = pageWidth - marginLeft - marginRight;
    const maxImageHeight = maxBlockHeight * 0.8; // 80% para a imagem
    const maxDescriptionHeight = maxBlockHeight * 0.6; // 60% para a descrição

    // Função para Adicionar Cabeçalho
    const addHeader = () => {
      doc.addImage(logo, 'JPEG', marginLeft, marginTop, 84, 112);
      const textXStart = marginLeft + 84 + 15;
      const textYStart = marginTop + 25;

      doc.setFontSize(14);
      doc.setFont('helvetica', 'bold');
      doc.text('Secretaria da Segurança Pública', textXStart, textYStart);
      doc.setFontSize(16);
      doc.text('POLÍCIA CIVIL DO ESTADO DE SÃO PAULO', textXStart, textYStart + 16);
      doc.setFontSize(12);
      doc.text('Departamento Estadual de Homicídios e Proteção à Pessoa – DHPP', textXStart, textYStart + 31);
      doc.text('Divisão de Homicídios "Dr. FRANCISCO DE ASSIS CAMARGO MAGANO"', textXStart, textYStart + 46);
      doc.text('Grupo Especial de Atendimento a Local de Crime – GEACRIM', textXStart, textYStart + 61);
      doc.text(`Grupo ${selectedGroup}`, textXStart, textYStart + 76);

      doc.setFontSize(12);
      doc.setFont('helvetica', 'normal');
      doc.text(`Boletim de Ocorrência ${boNumber} Versão ${version}`, pageWidth - marginRight, textYStart + 91, { align: 'right' });
    };

    // Função para Adicionar Rodapé
    const addFooter = () => {
      doc.setFontSize(10);
      const footerText1 = 'Endereço: Rua Brigadeiro Tobias, 527 – Centro – São Paulo/SP – CEP 01032-001';
      const footerText2 = 'Telefone: (11) 3311-3980   |   Email: dhpp.dh@policiacivil.sp.gov.br';
      doc.text(footerText1, pageWidth / 2, pageHeight - marginBottom, { align: 'center' });
      doc.text(footerText2, pageWidth / 2, pageHeight - marginBottom + 15, { align: 'center' });
    };

    // Função para Adicionar Título
    const addTitle = () => {
      doc.setFontSize(20);
      doc.setFont('helvetica', 'bold');
      const titleY = marginTop + headerHeight + spacingBetweenElements + 50;
      doc.text('Relatório Fotográfico', pageWidth / 2, titleY, { align: 'center' });
      return titleY + 30; // Espaçamento após o título
    };

    // Função para Adicionar um Bloco de Foto (Imagem + Descrição)
    const addPhotoBlock = (photo, currentY) => {
      // Adicionar Imagem
      const imageDataUrl = photo.photo;
      const imgProps = doc.getImageProperties(imageDataUrl);
      const imgWidth = imgProps.width;
      const imgHeight = imgProps.height;

      const scale = Math.min(maxImageWidth / imgWidth, maxImageHeight / imgHeight, 1);
      const scaledWidth = imgWidth * scale;
      const scaledHeight = imgHeight * scale;

      const x = (pageWidth - scaledWidth) / 2;
      const y = currentY;

      doc.addImage(imageDataUrl, 'JPEG', x, y, scaledWidth, scaledHeight);
      let newY = y + scaledHeight + spacingBetweenImageAndDescription;

      // Adicionar Descrição
      if (photo.description) {
        doc.setFontSize(12);
        doc.setFont('helvetica', 'normal');

        const textLines = doc.splitTextToSize(photo.description, maxImageWidth);
        const requiredHeight = textLines.length * lineHeight;

        if (requiredHeight > maxDescriptionHeight) {
          // Truncar o texto se exceder a altura máxima
          const maxLines = Math.floor(maxDescriptionHeight / lineHeight);
          const truncatedText = textLines.slice(0, maxLines - 1).join(' ') + '...';
          doc.text(truncatedText, pageWidth / 2, newY, { align: 'center' });
          newY += (maxLines * lineHeight) + spacingBetweenElements;
        } else {
          doc.text(textLines, pageWidth / 2, newY, { align: 'center' });
          newY += requiredHeight + spacingBetweenElements;
        }
      } else {
        // Se não houver descrição, apenas adicionar espaçamento
        newY += spacingBetweenElements;
      }

      return newY;
    };

    // Função para Iniciar uma Nova Página com Cabeçalho, Título e Rodapé
    const startNewPage = (currentY) => {
      doc.addPage();
      addHeader();
      const titleY = addTitle();
      return titleY;
    };

    // Adicionar Cabeçalho e Título na Primeira Página
    addHeader();
    let currentY = addTitle();

    // Ordenar Fotos por Posição
    const sortedPhotos = photos.slice().sort((a, b) => a.position - b.position);

    // Loop para Adicionar Fotos, Duas por Página
    for (let i = 0; i < sortedPhotos.length; i += photosPerPage) {
      // Adicionar duas fotos
      for (let j = i; j < i + photosPerPage && j < sortedPhotos.length; j++) {
        const photo = sortedPhotos[j];
        currentY = addPhotoBlock(photo, currentY);
      }

      // Adicionar Rodapé após as fotos
      addFooter();

      // Se houver mais fotos, iniciar uma nova página
      if (i + photosPerPage < sortedPhotos.length) {
        currentY = startNewPage(currentY);
      }
    }

    // Salvar o PDF
    doc.save('relatório-fotográfico.pdf');
    console.log('PDF gerado e salvo como "relatório-fotográfico.pdf".');
  } catch (error) {
    console.error('Erro ao gerar o PDF:', error);
    alert('Ocorreu um erro ao gerar o PDF. Verifique o console para mais detalhes.');
  }
};


  // Função para apagar todo o relatório
  const clearReport = async () => {
    const confirmClear = window.confirm('Você realmente deseja iniciar um novo relatório? Isso apagará todas as fotos, descrições e rotações.');
    if (!confirmClear) return;

    try {
      const db = await dbPromise;
      await db.clear('photos');
      console.log('Todos os dados do object store "photos" foram apagados.');
      setPhotos([]);

      // Limpar valores do localStorage
      localStorage.removeItem('selectedGroup');
      localStorage.removeItem('boNumber');
      localStorage.removeItem('version');

      // Resetar estados
      setSelectedGroup('1');
      setBoNumber('');
      setVersion('1');

    } catch (error) {
      console.error('Erro ao limpar o relatório:', error);
    }
  };

  return (
    <>
      {isDesktop ? (
        <div className="app-container">
          {/* Cabeçalho com Logo */}
          <div className="app-header">
            <img src={logo} alt="Logo da Aplicação" className="app-logo" />
            <h1>Gerador de Relatório Fotográfico</h1>
          </div>

          {/* Entrada do número do BO e versão */}
          <div className="bo-input-container">
            <InputMask
              mask="AA9999/99"
              maskChar={null}
              value={boNumber}
              onChange={handleBoInput}
              formatChars={{
                'A': '[A-Za-z]',
                '9': '[0-9]',
              }}
            >
              {(inputProps) => (
                <input
                  {...inputProps}
                  type="text"
                  className="bo-input"
                  placeholder="AB1234/56"
                  title="Formato: AA9999/99"
                  autoComplete="off"
                  autoCorrect="off"
                  spellCheck="false"
                  inputMode="text"
                />
              )}
            </InputMask>
            <select
              className="version-select"
              value={version}
              onChange={(e) => setVersion(e.target.value)}
            >
              {[...Array(10)].map((_, i) => (
                <option key={i} value={i + 1}>
                  Versão {i + 1}
                </option>
              ))}
            </select>
          </div>

          {/* Seletor de Grupo */}
          <div className="group-selector">
            <label htmlFor="group-select">Selecione o Grupo:</label>
            <select
              id="group-select"
              value={selectedGroup}
              onChange={(e) => setSelectedGroup(e.target.value)}
            >
              <option value="1">Grupo 1</option>
              <option value="2">Grupo 2</option>
              <option value="3">Grupo 3</option>
              <option value="4">Grupo 4</option>
              <option value="5">Grupo 5</option>
            </select>
          </div>

          {/* Botão de Importar Foto */}
          <div className="import-button-container">
            <input
              type="file"
              accept="image/*"
              multiple
              id="import-input"
              style={{ display: 'none' }}
              onChange={handleImportPhotos}
            />
            <button onClick={() => document.getElementById('import-input').click()}>
              Importar Foto
            </button>
          </div>

          {/* Lista de fotos */}
          <div className="photo-list">
            {photos.map((photo) => (
              <PhotoItem
                key={photo.id}
                id={photo.id}
                photo={photo}
                index={photo.position - 1}
                totalPhotos={photos.length}
                setDescription={setDescriptionHandler}
                removePhoto={removePhotoHandler}
                setPhotoPosition={setPhotoPositionHandler}
                rotatePhoto={rotatePhotoHandler} // Passando corretamente a função rotatePhoto
              />
            ))}
          </div>
          <br />
          {/* Agrupamento dos botões em um contêiner */}
          <div className="action-buttons">
            <button onClick={generatePDFReport} className="export-button">Exportar como PDF</button>
            <button onClick={clearReport} className="start-button">Iniciar Novo Relatório</button>
          </div>

          {/* Efeito de Flash */}
          {flash && <div className="flash-overlay"></div>}
        </div>
      ) : (
        isLandscape ? (
          <div className="landscape-mode">
            {/* Componentes da câmera em modo paisagem */}
            <div
              className="camera-container-fullscreen"
              ref={cameraContainerRef}
            >
              <video
                ref={videoRef}
                autoPlay
                playsInline
                muted
                className="video-fullscreen"
              ></video>
              <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
              
              {/* Grupo de Botões Organizados Equidistantemente e Alinhados à Direita */}
              <div className="button-group">
                {/* Botão da Lanterna */}
                <button 
                  onClick={() => setTorchModeState(toggleTorchMode(torchMode))} 
                  className="torch-button" 
                  title="Alternar Lanterna"
                >
                  <FontAwesomeIcon icon="lightbulb" />
                </button>
                
                {/* Botão de Captura de Foto */}
                <button onClick={handleTakePhoto} className="capture-button" title="Capturar Foto">
                  <FontAwesomeIcon icon="camera" />
                </button>
                
                {/* Botão para alternar câmera */}
                <button onClick={toggleCamera} className="toggle-camera-button" title="Alternar Câmera">
                  <FontAwesomeIcon icon="sync-alt" />
                </button>
              </div>
            </div>
            
            {/* Efeito de Flash */}
            {flash && <div className="flash-overlay"></div>}
            
            {/* Informar sobre o suporte ao torch, se necessário */}
            {torchError && !torchSupported && (
              <div className="torch-warning">
                <p>Lanterna não suportada neste dispositivo ou navegador.</p>
              </div>
            )}
          </div>
        ) : (
          <div className="app-container">
            {/* Cabeçalho com Logo */}
            <div className="app-header">
              <img src={logo} alt="Logo da Aplicação" className="app-logo" />
              <h1>Gerador de Relatório Fotográfico</h1>
            </div>

            {/* Entrada do número do BO e versão */}
            <div className="bo-input-container">
              <InputMask
                mask="AA9999/99"
                maskChar={null}
                value={boNumber}
                onChange={handleBoInput}
                formatChars={{
                  'A': '[A-Za-z]',
                  '9': '[0-9]',
                }}
              >
                {(inputProps) => (
                  <input
                    {...inputProps}
                    type="text"
                    className="bo-input"
                    placeholder="AB1234/56"
                    title="Formato: AA9999/99"
                    autoComplete="off"
                    autoCorrect="off"
                    spellCheck="false"
                    inputMode="text"
                  />
                )}
              </InputMask>
              <select
                className="version-select"
                value={version}
                onChange={(e) => setVersion(e.target.value)}
              >
                {[...Array(10)].map((_, i) => (
                  <option key={i} value={i + 1}>
                    Versão {i + 1}
                  </option>
                ))}
              </select>
            </div>

            {/* Seletor de Grupo */}
            <div className="group-selector">
              <label htmlFor="group-select">Selecione o Grupo:</label>
              <select
                id="group-select"
                value={selectedGroup}
                onChange={(e) => setSelectedGroup(e.target.value)}
              >
                <option value="1">Grupo 1</option>
                <option value="2">Grupo 2</option>
                <option value="3">Grupo 3</option>
                <option value="4">Grupo 4</option>
                <option value="5">Grupo 5</option>
              </select>
            </div>

            {/* Botão de Importar Foto */}
            <div className="import-button-container">
              <input
                type="file"
                accept="image/*"
                multiple
                id="import-input"
                style={{ display: 'none' }}
                onChange={handleImportPhotos}
              />
              <button onClick={() => document.getElementById('import-input').click()}>
                Importar Foto
              </button>
            </div>

            {/* Lista de fotos */}
            <div className="photo-list">
              {photos.map((photo) => (
                <PhotoItem
                  key={photo.id}
                  id={photo.id}
                  photo={photo}
                  index={photo.position - 1}
                  totalPhotos={photos.length}
                  setDescription={setDescriptionHandler}
                  removePhoto={removePhotoHandler}
                  setPhotoPosition={setPhotoPositionHandler}
                  rotatePhoto={rotatePhotoHandler} // Passando corretamente a função rotatePhoto
                />
              ))}
            </div>
            <br />
            {/* Agrupamento dos botões em um contêiner */}
            <div className="action-buttons">
              <button onClick={generatePDFReport} className="export-button">Exportar como PDF</button>
              <button onClick={clearReport} className="start-button">Iniciar Novo Relatório</button>
            </div>

            {/* Efeito de Flash */}
            {flash && <div className="flash-overlay"></div>}
          </div>
        )
      )}
    </>
  );
}

export default PhotoReportApp;
